import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Lede } from '../Lede';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "lede"
    }}>{`Lede`}</h1>
    <p>{`The Lede is used for the main introducory paragraph after big page Headings. It inherites the style from it's parent, but can be overridden, say to change the `}<inlineCode parentName="p">{`color`}</inlineCode>{`, `}<inlineCode parentName="p">{`fontWeight`}</inlineCode>{`,...`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Lede} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={1} __code={'<Heading as=\"h1\">Welcome to Hoppin</Heading>\n<Lede>\n  Scenester cornhole trust fund vaporware williamsburg. Selfies tbh tumeric\n  XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af\n  tattooed art party asymmetrical cardigan sustainable.\n</Lede>\n<Paragraph>\n  It follows normal paragraphs. Cornhole trust fund vaporware williamsburg.\n  Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom locavore, kogi\n  everyday carry af tattooed art party asymmetrical cardigan sustainable. Tbh\n  cornhole post-ironic, literally hashtag ethical adaptogen brooklyn bushwick\n  distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy\n  90\'s everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      Paragraph,
      Lede,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Heading as='h1' mdxType="Heading">Welcome to Hoppin</Heading>
  <Lede mdxType="Lede">
    Scenester cornhole trust fund vaporware williamsburg. Selfies tbh tumeric
    XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af
    tattooed art party asymmetrical cardigan sustainable.
  </Lede>
  <Paragraph mdxType="Paragraph">
    It follows normal paragraphs. Cornhole trust fund vaporware williamsburg. Selfies tbh tumeric
    XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af
    tattooed art party asymmetrical cardigan sustainable. Tbh cornhole
    post-ironic, literally hashtag ethical adaptogen brooklyn bushwick
    distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy
    90's everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      